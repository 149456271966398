<template>
  <div class="content_area">
    <el-image
      v-if="url"
      :src="url"
    ></el-image>
  </div>
</template>

<script>
export default {
  data(){
    return {
      url:"",
    }
  },
  mounted(){
    this.getHistoryList()
  },
  methods:{
    async getHistoryList() {
      this.rightLoading = true;
      let params = {};
      params.content_type = 18;
      params.source = this.PJSource;
      params.language = this.LOCALE === "en" ? 1 : 2;
      params.is_show = 1;
      let res = await this.$store.dispatch(
        "API_index/getStaticContent",
        params
      );
      if (res.success && res.data.length) {
        this.url=res.data[0].img_url
      }
    },
  }
};
</script>

<style lang="less" scoped>
.content_area {
  padding: 50px 0;
  background-color: #fff;
}
</style>